@import '@scss/all.scss';

.dse-footer-logo .logo-wrapper {
    width: 14.063rem;
    @include phone {
        width: 10.536rem;
    }
}

.footer-column-title {
    @include phone {
        margin-top: rem(20);
    }
}

.dse-footer-info {
    .dse-footer-logo {
        @include phone {
            margin-bottom: 1.25rem;
        }
    }
    .footer-info-wrapper {
        @include phone {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
            text-align: center;

            .links-container {
                grid-template-columns: 1fr;
                margin-bottom: 1.5rem;
                border-bottom: 1px solid #d9d9d6;

                .footer-column {
                    border-top: 1px solid $metlife-gray-light;
                }
            }
        }
    }

    .extra-section {
        @include phone {
            padding-top: 0;
            border-top: 0;
        }
    }
}
