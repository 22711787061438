@use "sass:math";

/*=============================================
=            Breakpoints                      =
=============================================*/

$breakpoints: (
  sm: 30rem,
  // 480px
  md: 67.5rem,
  // 800px
  lg: 67.51rem,
  // 1366
  xl: 85.376rem,
  // 1366 and more
);

@mixin desktop {
  @media (min-width: map-get($breakpoints, 'xl')) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: map-get($breakpoints, 'md')) and (max-width: (map-get($breakpoints, 'lg'))) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: map-get($breakpoints, 'sm')) and (max-width: (map-get($breakpoints, 'md'))) {
    @content;
  }
}

@mixin phone {
  @media ((max-width: map-get($breakpoints, 'sm'))) {
    @content;
  }
}