/* Generic */

@import 'normalize.scss';
@import '@scss/all.scss';

body:has(div):has(.menu-open) {
    overflow-y: hidden;
}

.APP_NAME {
    margin: auto;
}

/* OVERRIDE DS STYLES */

.dse-button .label {
    font-weight: 600;
}

.dse-tab .label {
    font-weight: 500;
}

/* END OF OVERRIDE DS STYLES */
